import { Link } from 'gatsby';
import React from 'react';

import Layout, { Seo } from '../../../../components/Layout';
import Cover from '../../../../components/Elements/Cover';
import PageSection from '../../../../components/Elements/PageSection';
import Row, { Col } from '../../../../components/Elements/Grid';
import NewsLink from '../../../../components/Pages/News/components/NewsLink';
import SignUp from '../../../../components/Parts/SignUp';
import withTranslations from '../../../../i18n/withTranslations';

const Item = () => (
  <Layout className="news">
    <Seo title={"Over ons | Nieuws | Let's get next level together"} />
    <Cover image="avocado">
      <PageSection top center>
        <h1>Nieuws</h1>
        <div className="article news-letter">
          <div className="header">
            <p className="details">Gepubliceerd op 29 oktober 2019 door Jesper Weiland</p>
          </div>
          <div className="body">
            <Row>
              <h1>
                Let&lsquo;s get
                <br />
                next level
                <br />
                together
                <span className="red">.</span>
              </h1>
              <p>
                Daar zijn we dan, het nieuwe Exonet. We hebben 17,5 jaar aan onze interne processen
                gewerkt, maar eigenlijk weinig verteld over wat we doen. We hebben onze visie een
                upgrade gegeven: Follow the future noemen we dit.
              </p>
              <p>
                Deze visie hebben we samengesteld door met onze klanten en ons team in gesprek te
                gaan. Wij geloven dat het binnen ons vak belangrijk is steeds naar de toekomst te
                kijken, en daar goed op te anticiperen. Alleen op die manier kunnen wij voorop
                blijven lopen in de markt, en iedereen de beste mogelijke dienstverlening geven.
              </p>
              <p>
                Om onze verstevigde visie kracht bij te zetten doen we er alles aan om
                toekomstbestendig te zijn. Naast ons nieuwe pand, de nieuwe website en onze nieuwe
                huisstijl zijn we intern met interessante nieuwe diensten bezig.
              </p>
              <p>
                Focus is the strength of success. We bieden daarom alleen&nbsp;
                <Link to="/managed-hosting">next-level managed hosting</Link>
                &nbsp; en dat doen we goed. De tijd van het verhuren van &quot;een servertje&quot;
                ligt ver achter ons. Wij luisteren naar jouw wensen en bouwen daar telkens een
                nieuwe, perfect passende oplossing voor. Zonder eenmalige kosten, langdurige
                contracten of &quot;uurtje factuurtje&quot;. All-in service zonder zorgen.
              </p>
            </Row>
            <hr className="divider" />
            <Row>
              <h2>
                Greenberry
                <br />
                ft
                <span className="red">.</span>
                &nbsp;Exonet
              </h2>
              <p>
                Met onze partners bouwen we aan de toekomst. Om een beter inzicht te geven in onze
                partnersamenwerkingen en onze visie op de toekomst maken wij&nbsp;
                <Link to="/onze-aanpak/cases">casevideo&apos;s</Link>
                .
              </p>
              <p>
                De eerste casevideo hebben we met Greenberry gemaakt. In deze video legt Bas van
                Bokhorst, Technology Director bij&nbsp;
                <a
                  rel="noopener noreferrer"
                  href="https://www.greenberry.nl"
                  aria-label="Greenberry"
                  target="_blank"
                >
                  Greenberry
                </a>
                , uit wat de samenwerking met Exonet voor hem betekent. Ook vertelt hij over een
                mooi gezamenlijk project.
              </p>
              <p>
                De komende tijd zullen we meer casevideo&apos;s gaan maken. Je ziet hierin hoe
                uiteenlopende bedrijven met ons samenwerken.
              </p>
              <p className="bumper">Bekijk onderstaande video:</p>
              <Col video>
                <iframe
                  title="Greenberry"
                  src="https://player.vimeo.com/video/363534758"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  sandbox="allow-same-origin allow-scripts"
                />
              </Col>
            </Row>
            <hr className="divider" />
            <Row>
              <Col story>
                <img
                  className="image"
                  src="/images/news/now-what.gif"
                  alt="Now what? You tell us."
                />
                <h3>
                  Now what
                  <span className="red">?</span>
                  <br />
                  You tell us
                  <span className="red">.</span>
                </h3>
                <p>
                  Wij zijn uiteraard heel erg benieuwd naar jouw bevindingen! Heb je feedback over
                  de website of onze aangescherpte visie?
                  <br />
                  <br />
                  <Link to="/contact">Vertel het ons</Link>
                </p>
              </Col>
              <Col story>
                <img
                  className="image"
                  src="/images/news/party-time.gif"
                  alt="Now what? You tell us."
                />
                <h3>
                  Party time
                  <span className="red">?</span>
                  <br />
                  Be patient
                  <span className="red">.</span>
                </h3>
                <p>
                  We zijn eind september naar Zevenaar verhuisd, maar we vieren onze housewarming in
                  het begin van 2020 als alles af is.
                  <br />
                  <br />
                  <a href="mailto:info@exonet.nl?subject=Ik wil graag op visite komen">
                    Ik kan niet wachten!
                  </a>
                </p>
              </Col>
            </Row>
            <hr className="divider" />
            <Row>
              <h2>
                What
                <span className="red">&apos;</span>
                s
                <br />
                next
                <span className="red">?</span>
              </h2>
              <p>
                Onze nieuwe visie zorgt ervoor dat we nog wat dichter bij onze klanten zullen staan.
                Er zal dus meer gecommuniceerd gaan worden. We willen je meer laten weten waar we
                mee bezig zijn, zodat we nog beter samen kunnen werken.
              </p>
              <p>Wat kan je concreet van ons verwachten?</p>
              <ul className="list">
                <li>Een maandelijkse nieuwsbrief;</li>
                <li>Een officiële opening van Exonet HQ begin 2020;</li>
                <li>(Kennisgedreven-)events bij ons op kantoor;</li>
                <li>Groei in aantal medewerkers.</li>
              </ul>
              <p>
                We hopen je hiermee op de hoogte te houden van de ontwikkelingen binnen Exonet en de
                toekomst van ons vakgebied.
              </p>
            </Row>
            <hr className="divider" />
            <Row>
              <h2>
                Get
                <br />
                ready
                <span className="red">.</span>
              </h2>
              <p>
                Hoewel we al 17,5 jaar bestaan, is het voor mijn gevoel pas net begonnen. We hebben
                al die tijd gebouwd aan de kennis en diensten die we nu in huis hebben.
              </p>
              <p>
                Ik verheug me enorm op de mooie dingen die we de komende tijd gaan maken. Wil je ook
                eens sparren over jullie toekomst? Dat doe ik graag. Bel gerust!
              </p>
              <p>
                Groetjes,
                <br />
                Jesper
                <span className="red">.</span>
              </p>
            </Row>
            <hr className="divider white" />
          </div>
          <div className="nav">
            <NewsLink
              article={{
                id: '93',
                title: 'Maatregelen naar aanleiding van kwetsbaarheid in PHP',
                date: '2019-10-28 11:00:00',
                user_id: '11',
                author: '',
              }}
            />
          </div>
        </div>
        <SignUp />
      </PageSection>
    </Cover>
  </Layout>
);

export default withTranslations(Item);
